<template>
  <input v-if="editable" :ref="inputElementId" type="text" @click="cursorHandler" v-bind="$attrs"
    :value="formattedInputValue" @input="inputChangeHandler($event)" @blur="inputBlurHandler($event)" />
  <label v-else v-bind="$attrs" @click="changeToEditModeHandler">
    {{ this.readonlyModeFormatter(this.inputValue) }}
  </label>
</template>
<script>
/*eslint-disable*/
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { getDynamicRegex } from '@/services/utils/regexUtils';
export default {
  name: 'NAGP-goal-base-input-decimal',
  model: {
    prop: 'inputValue',
    event: 'input',
    default: null
  },
  props: {
    inputValue: {
      type: String,
      required: true
    },
    maximumDigitsAllowed: {
      type: Number,
      default: 6
    },
    inputNumberFormatter: {
      type: Function,
      default: FormatNumber
    },
    editable: {
      type: Boolean,
      default: true
    },
    readonlyModeFormatter: {
      type: Function,
      default: val => val
    },
    fractionalUnitDecimalSeparator: {
      type: String,
      default: ','
    }

  },
  data () {
    return {
      commonGoalValueDecimalSeparator: '.',
      inputElementId: null
    }
  },
  computed: {
    formattedInputValue () {
      if (this.inputValue === '') return ''
      const isLastCharacterCommonDecimalSeparator = this.inputValue[this.inputValue.length - 1] === this.commonGoalValueDecimalSeparator
      return isLastCharacterCommonDecimalSeparator
        ? this.inputNumberFormatter(this.inputValue) + this.fractionalUnitDecimalSeparator
        : this.inputNumberFormatter(this.inputValue)
    }
  },
  methods: {
    changeToEditModeHandler () {
      this.$emit('editableMode', true)
      this.$nextTick(() => {
        // Trigger focus on the input once edit mode is on
        this.$refs[this.inputElementId].focus()
      })
    },
    inputBlurHandler (event) {
      this.$emit('blur', event)
      this.$emit('editableMode', false)
    },
    deFormatToNumber (numberWithCommas) {
      return String(numberWithCommas).replaceAll(',', '')
    },
    cursorHandler (event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === 0) {
        const length = event.target.value.length
        event.target.setSelectionRange(length, length)
        event.target.focus()
      }
    },
    inputChangeHandler (event) {
      const matchPattern = new RegExp(`[^\\d${this.fractionalUnitDecimalSeparator}]`, 'g')
      const inputValue = event.target.value
      const EMPTY_STRING = ''
      let isLastCharFractionalUnitDecimalSeparator = false
      // Remove all non-numeric and non fractional decimal separator character
      let currentValue = inputValue.replaceAll(matchPattern, EMPTY_STRING)
      // Keep only the first decimal separator and remove any additional separator characters
      currentValue = currentValue
        .replace(getDynamicRegex(this.fractionalUnitDecimalSeparator), '#')
        .replace(getDynamicRegex(this.fractionalUnitDecimalSeparator, 'g'), EMPTY_STRING)
        .replace('#', this.fractionalUnitDecimalSeparator)

      const splits = currentValue.split(this.fractionalUnitDecimalSeparator)

      if (splits.length === 1) {
        currentValue = currentValue
      } else if (splits[1].length === 0) {
        isLastCharFractionalUnitDecimalSeparator = true
        currentValue = splits[0] + '.'
      }
      else {
        currentValue = splits[0] + this.commonGoalValueDecimalSeparator + splits[1].substring(0, 2)
      }

      if (currentValue === EMPTY_STRING) {
        this.$emit('input', EMPTY_STRING)
        event.target.value = EMPTY_STRING
        return
      }
      if (currentValue.replace(this.commonGoalValueDecimalSeparator, EMPTY_STRING).toString().length > this.maximumDigitsAllowed) {
        this.$emit('input', this.inputValue)
        event.target.value = this.inputNumberFormatter(this.inputValue)
      } else {
        // if currentValue goal value is single character and is equal to decimal separator (which is dot '.'), set to empty string
        currentValue = currentValue === this.commonGoalValueDecimalSeparator ? EMPTY_STRING : currentValue
        this.$emit('input', currentValue)
        if (isLastCharFractionalUnitDecimalSeparator) {
          // Preventing from passing the value to formatter function to avoid exception if the goal value is empty string
          const result = currentValue === EMPTY_STRING ? currentValue : this.inputNumberFormatter(currentValue) + this.fractionalUnitDecimalSeparator
          event.target.value = result
        } else {
          // At this point, we will always get a valid decimal goal value
          event.target.value = this.inputNumberFormatter(currentValue)
        }
      }
    }
  },
  created () {
    this.inputElementId = crypto.randomUUID()
  }
}
</script>
