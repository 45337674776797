/*eslint-disable */
/**
 * @const {Object} NAGPShortCodes
 */
export const NAGPShortCodes = {
  BarnabasFund: 'nag_barnabas_fund',
  GideonScripture: 'nag_gideon_scripture',
  AuxiliaryScripture: 'nag_auxiliary_scripture',
  AdminReceipts: 'nag_admin_reciepts',
  GideonPlacements: 'nag_gideon_placements',
  AuxiliaryPlacements: 'nag_auxiliary_placements',
  GideonPWT: 'nag_gideons_pwt',
  AuxiliaryPWT: 'nag_auxiliary_pwt',
  ChurchPresentations: 'nag_church_presentation',
  GideonMembership: 'nag_gideons_membership',
  AuxiliaryMembership: 'nag_auxiliary_membership'
}

/**
 * @const {Object} NAGPFormKeys
 * @property {string} BARNABAS_FUND
 */
export const NAGPFormKeys = {
  BARNABAS_FUND: 'barnabasFund',
  GIDEON_SCRIPTURE: 'gideonScripture',
  AUXILIARY_SCRIPTURE: 'auxiliaryScripture',
  ADMIN_RECEIPTS: 'adminReceipts',
  GIDEON_PLACEMENTS: 'gideonPlacements',
  AUXILIARY_PLACEMENTS: 'auxiliaryPlacements',
  GIDEON_PWT: 'gideonPWT',
  AUXILIARY_PWT: 'auxiliaryPWT',
  CHURCH_PRESENTATION: 'churchPresentation',
  GIDEON_MEMBERSHIP: 'gideonMembership',
  AUXILIARY_MEMBERSHIP: 'auxiliaryMembership'
}

/**
 * @const {Map<string, string>}
 */
export const NAGPShortCodeFormKeyMap = new Map([
  [NAGPShortCodes.BarnabasFund, NAGPFormKeys.BARNABAS_FUND],
  [NAGPShortCodes.GideonScripture, NAGPFormKeys.GIDEON_SCRIPTURE],
  [NAGPShortCodes.AuxiliaryScripture, NAGPFormKeys.AUXILIARY_SCRIPTURE],
  [NAGPShortCodes.AdminReceipts, NAGPFormKeys.ADMIN_RECEIPTS],
  [NAGPShortCodes.GideonPlacements, NAGPFormKeys.GIDEON_PLACEMENTS],
  [NAGPShortCodes.AuxiliaryPlacements, NAGPFormKeys.AUXILIARY_PLACEMENTS],
  [NAGPShortCodes.GideonPWT, NAGPFormKeys.GIDEON_PWT],
  [NAGPShortCodes.AuxiliaryPWT, NAGPFormKeys.AUXILIARY_PWT],
  [NAGPShortCodes.ChurchPresentations, NAGPFormKeys.CHURCH_PRESENTATION],
  [NAGPShortCodes.GideonMembership, NAGPFormKeys.GIDEON_MEMBERSHIP],
  [NAGPShortCodes.AuxiliaryMembership, NAGPFormKeys.AUXILIARY_MEMBERSHIP]
])

/**
 * @const {Object} NAGoalCategory
 */
export const NAGoalCategory = {
  Receipts: 'RECEIPTS',
  Placements: 'PLACEMENTS',
  PWTs: 'PERSONAL WORKERS TESTAMENTS',
  ChurchPresentations: 'CHURCH PRESENTATIONS',
  Membership: 'MEMBERSHIP'
}

/**
 * @const {Object} NAGPCountryKeys
 */
export const NAGPCountryKeys = {
  BRAZIL: 'CC9CA760-7E3F-400C-821B-D53C86AE0A6F',
  PHILIPPINES: '2308F2D5-1C7C-4D05-BDFB-084A477344B3',
  FINLAND: '93653D04-8E0A-486E-BC74-C8180DDC7589',
  AUSTRALIA: '2D3F4BCD-90AF-4889-990A-FF02D60AC307',
  GERMANY: 'DA8646D0-A6DA-49D2-B946-794BFD8BF6EB',
  INDIA: '518B4B65-DBF9-4486-BFE0-D0C659B24B5B',
  SOUTH_AFRICA: '4DA56924-A340-46C0-9E35-169E53AB7A83',
  JAPAN: '0634E625-E473-49D7-A7DD-17020EEC6AC7',
  REPUBLIC_OF_CHINA: '831A17FA-2BC5-4C90-B4EE-6FA7D2AD9C94',
  NORWAY: '85D2DB9E-F8DA-47DA-8FDA-C9E706EE2FD8',
  KOREA: 'BEF99BAA-242F-44EF-98DE-94F692B46481',
  NIGERIA: 'F6AE129A-4C7D-46AD-8330-AAC181228463'
}

//TODO: Remove this once LocaleCurrencyCountryMap is fully functional
/**
 * @const {Map<string, string>} NAGPCountryKeyLocalCurrencyMap
 */
export const NAGPCountryKeyLocalCurrencyMap = new Map([
  [NAGPCountryKeys.BRAZIL, 'R$ Real'],
  [NAGPCountryKeys.PHILIPPINES, '₱ Peso'],
  [NAGPCountryKeys.FINLAND, '€ EUR'],
  [NAGPCountryKeys.AUSTRALIA, '$ AUS'],
  [NAGPCountryKeys.GERMANY, '€ EUR'],
  [NAGPCountryKeys.INDIA, '₹ INR'],
  [NAGPCountryKeys.SOUTH_AFRICA, 'R Rand'],
  [NAGPCountryKeys.JAPAN, '¥ YEN'],
  [NAGPCountryKeys.REPUBLIC_OF_CHINA, 'NT$ TWD'], //TODO: Change locale and currency map below once verified
  [NAGPCountryKeys.NORWAY, 'kr NOK'], //TODO: Need to confirm
  [NAGPCountryKeys.KOREA, '₩ WON'],
  [NAGPCountryKeys.NIGERIA, '₦ Naria']
])

/**
 * @let {Map<string, Object>} LocaleCurrencyCountryMap
 */
let LocaleCurrencyCountryMap = new Map();

export const createLocaleCurrencyCountryMap = (apiResponse) => {
  LocaleCurrencyCountryMap = new Map(
    apiResponse.map(country => [
      country.cnt_key.toUpperCase(),
      {
        locale: country.lang_cnt_key,
        currency: country.cnt_currency,
      },
    ])
  );
};
/**
 * @const {Map<string, string>} NACountryKeyToCurrencyFractionalUnitSeparatorMap
 */
export const NACountryKeyToCurrencyFractionalUnitSeparatorMap = new Map([
  [NAGPCountryKeys.BRAZIL, ','],
  [NAGPCountryKeys.PHILIPPINES, '.'],
  [NAGPCountryKeys.FINLAND, ','],
  [NAGPCountryKeys.AUSTRALIA, '.'],
  [NAGPCountryKeys.GERMANY, ','],
  [NAGPCountryKeys.INDIA, '.'],
  [NAGPCountryKeys.SOUTH_AFRICA, '.'],
  [NAGPCountryKeys.JAPAN, '.'],
  [NAGPCountryKeys.REPUBLIC_OF_CHINA, '.'],
  [NAGPCountryKeys.KOREA, '.'],
  [NAGPCountryKeys.NORWAY, ','],
  [NAGPCountryKeys.NIGERIA, '.']
])


export const getLocaleCurrencyCountryMap = () => {
  return LocaleCurrencyCountryMap;
};

export const NAGP_GOAL_MIN_VALUE = 1.00 //!IMPORTANT: All calculated related to NAGP are done with Decimal data type (third party package) and it can hold very large value that normal Number can't. Don't use normal Number primitive for calculation purposes
export const NAGP_GOAL_MAX_DIGIT = 26
