<template>
  <div>
    <div class="stories template-1">
      <header class="page-header container mx-md">
        <h1>NATIONAL ASSOCIATION GOAL PLANNER</h1>
      </header>
    </div>
    <security-banner v-if="!allowPage" />
    <div v-if="allowPage">
      <section class="container camp-select mx-lg">
        <div
          class="d-flex align-items-center flex-wrap flex-sm-nowrap justify-content-start justify-content-sm-between">
          <h4 class="mb-4 mb-sm-0 w-100-sd">
            {{ selectedCountryName }}
          </h4>
          <form v-if="!!hasHeadquaterRole" class="filters d-flex justify-content-start justify-content-sm-end">
            <b-form-select v-model="countryKeyDropDownSelected" :options="countryOptions"
              class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0 form-control"></b-form-select>
            <button class="button-div" type="button" @click="countryGoHandler">GO</button>
          </form>
        </div>
      </section>
      <div class="page-body container mx-lg page-body-spacing ">
        <div class="card card--mt card--pb-0">
          <div class="main-heading">
            GOAL PLANNING DASHBOARD
            <span v-if="dashboardNagpData.IsLocked" class="camp-lock-text"> - NATIONAL ASSOCIATION IS LOCKED</span>
          </div>
          <div class="g-panel">
            <div class="g-panel__lft">
              <p>The heart of man plans his way, but the LORD establishes his steps. Proverbs 16:9</p>
              <ul>
                <li>Selected Fiscal Year: {{ fiscalYearRange }}</li>
              </ul>
            </div>
          </div>
          <section v-for="NAGoalItem in NAGPData" :key="NAGoalItem.NAGoalCategory" class="ngpa-input-grp">
            <div class="planner-header">
              <h4>{{ NAGoalItem.NAGoalCategory }}</h4>
            </div>
            <b-table hover striped :items="NAGoalItem.NAGoalsData" :fields="fields" class="custom-table"
              :foot-clone="isNACategoryReceiptsType(NAGoalItem.NAGoalCategory)">
              <template #cell(NAGoalName)="data">
                <div>
                  {{ data.item.NAGoalName }}
                  <img v-if="isNAGoalSubHeadingPlacement(data.item.NAShortCode)"
                    src="@/assets/svgs/goal-planner/ic_info.svg" width="20" alt="arrow"
                    v-b-tooltip.hover="{ customClass: 'custom-tooltip custom-tooltip--align-lft', html: true }"
                    :title="`Enter the anticipated number of Scriptures to be ordered for the upcoming fiscal year.`" />
                </div>
              </template>
              <template #cell(NAGoal)="data">
                <b-input-group size="lg"
                  :prepend="isNACategoryReceiptsType(NAGoalItem.NAGoalCategory) ? localCurrency : ''">

                  <NAGPGoalBaseInputDecimal
                    v-if="isSelectedNACountryKeyBrazil && isNACategoryReceiptsType(NAGoalItem.NAGoalCategory)"
                    @editableMode="editMode => NAGPInputEditModeHandler(data.item.NAFormKey, editMode)"
                    v-model="formData[data.item.NAFormKey]"
                    :fractionalUnitDecimalSeparator="currencyFractionalUnitSeparatorSymbolByCountryKey"
                    :editable="NAGPInputReadOnlyModes[data.item.NAFormKey]"
                    :readonlyModeFormatter="(goalValue) => inputNAGPGoalReadOnlyFormatter(goalValue, 2)"
                    :maximumDigitsAllowed="NAGPMaxGoalDigitForInput"
                    :inputNumberFormatter="(goalValue) => inputNAGPGoalFormatter(goalValue, 2)"
                    :disabled="dashboardNagpData.IsLocked" :class="{
                      'form-control form-control-sm': true,
                      disabled: dashboardNagpData.IsLocked || !dashboardNagpData.IsNagpFormWindowOpen
                    }" />

                  <NAGPGoalBaseInput v-else
                    @editableMode="editMode => NAGPInputEditModeHandler(data.item.NAFormKey, editMode)"
                    v-model="formData[data.item.NAFormKey]" :readonlyModeFormatter="inputNAGPGoalReadOnlyFormatter"
                    :editable="NAGPInputReadOnlyModes[data.item.NAFormKey]"
                    :maximumDigitsAllowed="NAGPMaxGoalDigitForInput" :inputNumberFormatter="inputNAGPGoalFormatter"
                    :disabled="dashboardNagpData.IsLocked" :class="{
                      'form-control form-control-sm': true,
                      disabled: dashboardNagpData.IsLocked || !dashboardNagpData.IsNagpFormWindowOpen
                    }" />
                </b-input-group>
                <div v-if="!$v.formData[data.item.NAFormKey].required" class="error">
                  Enter a valid value
                </div>
                <div v-else-if="!$v.formData[data.item.NAFormKey].minGoalValue" class="error">
                  Minimum value is {{ NAGPMinGoalValue }}
                </div>
              </template>
              <template v-if="isNACategoryReceiptsType(NAGoalItem.NAGoalCategory)" #foot(NAGoalName)="data">
                <b>Total</b>
              </template>
              <template v-if="isNACategoryReceiptsType(NAGoalItem.NAGoalCategory)" #foot(NAGoal)="data">
                <b>{{ calculatedTotalReceiptsGoals.NAGoalTotal }}</b>
              </template>
              <template v-if="isNACategoryReceiptsType(NAGoalItem.NAGoalCategory)" #foot(NAFY1Goal)="data">
                <b>{{ calculatedTotalReceiptsGoals.NAFY1GoalTotal }}</b>
              </template>
            </b-table>
          </section>

          <div class="planner-header">
            <h4>FINALIZE GOAL PLANNING <span>Lock Your Goal</span></h4>
          </div>
          <div class="g-panel">
            <div class="g-panel__lft">
              <p class="lft__width lft_font">
                Select the Lock National Association Goals button to finalize goal planning. National Association
                planning must be completed and finished by
                <strong>{{ dashboardNagpData.NagpPlanningCompletionDate }}</strong>
              </p>
            </div>
            <div class="g-panel__rgt">
              <span v-if="dashboardNagpData.IsLocked || !dashboardNagpData.IsNagpFormWindowOpen" tabindex="0"
                v-b-tooltip.hover="{ customClass: 'custom-tooltip' }" :title="`Goal cannot be saved as it is ${!dashboardNagpData.IsNagpFormWindowOpen ? 'frozen' : 'locked'}.`
                  ">
                <button class="btn btn-primary" @click="addOrUpdateNAGPGoalsHandler"
                  :disabled="dashboardNagpData.IsLocked || !dashboardNagpData.IsNagpFormWindowOpen">
                  Save
                </button>
              </span>
              <button v-else type="button" class="btn btn-primary" @click="addOrUpdateNAGPGoalsHandler"
                :disabled="dashboardNagpData.IsLocked || !dashboardNagpData.IsNagpFormWindowOpen">
                Save
              </button>
              <span v-if="!dashboardNagpData.IsNagpFormWindowOpen" tabindex="0"
                v-b-tooltip.hover="{ customClass: 'custom-tooltip' }" :title="`Goal cannot be ${dashboardNagpData.IsLocked ? 'unlocked' : 'locked'} as it is ${!dashboardNagpData.IsNagpFormWindowOpen ? 'frozen' : 'locked'
                  }.`
                  ">
                <button type="button" class="btn btn-secondary" @click="lockUnlockNAGPGoalsHandler"
                  :disabled="!dashboardNagpData.IsNagpFormWindowOpen">
                  {{ `${dashboardNagpData.IsLocked ? 'Unlock' : 'Lock'}` }} Your Goal
                </button>
              </span>
              <button v-else type="button" class="btn btn-secondary" @click="lockUnlockNAGPGoalsHandler"
                :disabled="!dashboardNagpData.IsNagpFormWindowOpen">
                {{ `${dashboardNagpData.IsLocked ? 'Unlock' : 'Lock'}` }} Your Goal
              </button>
            </div>
          </div>
          <div class="g-panel">
            <div class="g-panel__lft">
              <p class="lft_font">
                If you would like to save a copy of this form, please print this screen for your records.
              </p>
            </div>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import NationalAssociationGoalPlanningService from '@/services/nagp/NationalAssociationGoalPlanning.js'
import Decimal from 'decimal.js'
import {
  NAGoalCategory,
  NAGPFormKeys,
  NAGP_GOAL_MIN_VALUE,
  NAGP_GOAL_MAX_DIGIT,
  NAGPShortCodes,
  NACountryKeyToCurrencyFractionalUnitSeparatorMap,
  NAGPCountryKeys
} from '@/constants/nagp/NationalAssociationGoalPlanningConstants.js'
import { FormatNumber } from '@/services/utils/FormatNumber'
import { required } from 'vuelidate/lib/validators'
import NAGPGoalBaseInput from '@/components/nagp/NAGPGoalBaseInput.vue'
import NAGPGoalBaseInputDecimal from '@/components/nagp/NAGPGoalBaseInputDecimal.vue'
import { customGoalMinValidator } from '@/services/utils/NAGPCustomValidator.js'
import { YearRange } from '@/services/utils/YearRange.js'
import {
  getNGAPFormatCurrencyByCountryKey,
  getNAGPCurrencySymbolByCountryKey
} from '@/services/utils/CurrencyFormatter'
import { createLocaleCurrencyCountryMap } from '@/constants/nagp/NationalAssociationGoalPlanningConstants.js'

export default {
  name: 'national-association-goal-planning-NAGP',
  mixins: [translationMixin],
  components: {
    securityBanner: SecurityBanner,
    iTooltip: iTooltip,
    NAGPGoalBaseInput,
    NAGPGoalBaseInputDecimal
  },
  data () {
    return {
      countryKeyDropDownSelected: '',
      countryKeyForLocalCurrency: null,
      hasHeadquaterRole: false,
      NAGPData: [],
      dashboardNagpData: {
        FiscalYear: '',
        IsLocked: false,
        IsNagpFormWindowOpen: true,
        NagpPlanningCompletionDate: ''
      },
      NAGPInputReadOnlyModes: {
        [NAGPFormKeys.BARNABAS_FUND]: false,
        [NAGPFormKeys.GIDEON_SCRIPTURE]: false,
        [NAGPFormKeys.AUXILIARY_SCRIPTURE]: false,
        [NAGPFormKeys.ADMIN_RECEIPTS]: false,
        [NAGPFormKeys.GIDEON_PLACEMENTS]: false,
        [NAGPFormKeys.AUXILIARY_PLACEMENTS]: false,
        [NAGPFormKeys.GIDEON_PWT]: false,
        [NAGPFormKeys.AUXILIARY_PWT]: false,
        [NAGPFormKeys.CHURCH_PRESENTATION]: false,
        [NAGPFormKeys.GIDEON_MEMBERSHIP]: false,
        [NAGPFormKeys.AUXILIARY_MEMBERSHIP]: false
      },
      FY1GoalTotalByNACategoryMap: new Map(),
      formData: {
        [NAGPFormKeys.BARNABAS_FUND]: 0,
        [NAGPFormKeys.GIDEON_SCRIPTURE]: 0,
        [NAGPFormKeys.AUXILIARY_SCRIPTURE]: 0,
        [NAGPFormKeys.ADMIN_RECEIPTS]: 0,
        [NAGPFormKeys.GIDEON_PLACEMENTS]: 0,
        [NAGPFormKeys.AUXILIARY_PLACEMENTS]: 0,
        [NAGPFormKeys.GIDEON_PWT]: 0,
        [NAGPFormKeys.AUXILIARY_PWT]: 0,
        [NAGPFormKeys.CHURCH_PRESENTATION]: 0,
        [NAGPFormKeys.GIDEON_MEMBERSHIP]: 0,
        [NAGPFormKeys.AUXILIARY_MEMBERSHIP]: 0
      },
      NAGoalCategoryPlacementGideonPlacement: 'Gideon Placements',
      NAGoalCategoryPlacementAuxiliaryPlacements: 'Auxiliary Placements',
      tooltip: 'Tooltip message',
      countryOptions: [],
      fields: [
        {
          key: 'NAGoalName',
          label: ''
        },
        {
          key: 'NAGoal',
          label: 'National Association Goal'
        }
        //TODO: Need to uncomment when FY1 goal data is there
        // {
        //   key: 'NAFY1Goal',
        //   label: '2024-25 Goal'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      userName: 'user/userName',
      prefCulture: 'user/userPreferredCulture',
      userCountryObj: 'user/userCountryObject',
      userLogin: 'user/userLogin'
    }),
    isSelectedNACountryKeyBrazil () {
      return this.countryKeyDropDownSelected === NAGPCountryKeys.BRAZIL
    },
    currencyFractionalUnitSeparatorSymbolByCountryKey () {
      return NACountryKeyToCurrencyFractionalUnitSeparatorMap.get(this.countryKeyDropDownSelected.toUpperCase()) || ''
    },
    NAGPMaxGoalDigitForInput () {
      return NAGP_GOAL_MAX_DIGIT
    },
    NAGPMinGoalValue () {
      return NAGP_GOAL_MIN_VALUE
    },
    localCurrency () {
      return getNAGPCurrencySymbolByCountryKey(this.countryKeyForLocalCurrency)
    },
    fiscalYearRange () {
      return YearRange(parseInt(this.dashboardNagpData.FiscalYear))
    },
    NACategoryGoalsMap () {
      return new Map([
        [
          NAGoalCategory.Receipts,
          [
            NAGPFormKeys.BARNABAS_FUND,
            NAGPFormKeys.GIDEON_SCRIPTURE,
            NAGPFormKeys.AUXILIARY_SCRIPTURE,
            NAGPFormKeys.ADMIN_RECEIPTS
          ]
        ],
        [NAGoalCategory.Placements, [NAGPFormKeys.GIDEON_PLACEMENTS, NAGPFormKeys.AUXILIARY_PLACEMENTS]],
        [NAGoalCategory.PWTs, [NAGPFormKeys.GIDEON_PWT, NAGPFormKeys.AUXILIARY_PWT]],
        [NAGoalCategory.ChurchPresentations, [NAGPFormKeys.CHURCH_PRESENTATION]],
        [NAGoalCategory.Membership, [NAGPFormKeys.GIDEON_MEMBERSHIP, NAGPFormKeys.AUXILIARY_MEMBERSHIP]]
      ])
    },
    countryKeysMap () {
      const map = new Map()
      this.countryOptions.forEach(cnt => {
        map.set(cnt.value, cnt.text)
      })
      return map
    },
    calculatedTotalReceiptsGoals () {
      const val = this.calculateNAGoalsTotalByCategoryName(NAGoalCategory.Receipts)
      const minAndMaxFractionalDigits = this.isSelectedNACountryKeyBrazil ? 2 : 0
      const FY1TotalFormatted = getNGAPFormatCurrencyByCountryKey(
        this.FY1GoalTotalByNACategoryMap.get(NAGoalCategory.Receipts),
        this.countryKeyForLocalCurrency,
        minAndMaxFractionalDigits
      )
      const NAGoalTotalFormatted = getNGAPFormatCurrencyByCountryKey(val, this.countryKeyForLocalCurrency, minAndMaxFractionalDigits)
      return {
        NAGoalTotal: isNaN(val) ? '' : NAGoalTotalFormatted.formattedWithCurrency,
        NAFY1GoalTotal: FY1TotalFormatted.formattedWithCurrency
      }
    },
    selectedCountryName () {
      return this.countryKeysMap.get(this.countryKeyForLocalCurrency)
    },
    allowPage () {
      return this.$route.path.charAt(this.$route.path.length - 1) === '/'
        ? this.isInMenu(this.$route.path.slice(0, -1))
        : this.isInMenu(this.$route.path)
    }
  },
  methods: {
    isNAGoalSubHeadingPlacement (NAShortCode) {
      return NAGPShortCodes.GideonPlacements === NAShortCode || NAGPShortCodes.AuxiliaryPlacements === NAShortCode
    },
    NAGPInputEditModeHandler (formKey, wantToBeInEditMode) {
      this.NAGPInputReadOnlyModes[formKey] = wantToBeInEditMode
    },
    inputNAGPGoalReadOnlyFormatter (goalValue, minAndMaxFractionalDigits = 0) {
      return getNGAPFormatCurrencyByCountryKey(goalValue, this.countryKeyForLocalCurrency, minAndMaxFractionalDigits)
        .formattedWithDecimalAndWithoutCurrency
    },
    inputNAGPGoalFormatter (goalValue, minAndMaxFractionalDigits = 0) {
      return getNGAPFormatCurrencyByCountryKey(goalValue, this.countryKeyForLocalCurrency, minAndMaxFractionalDigits).formattedWithoutCurrency
    },
    async fetchNAGPGoalsData () {
      const {
        transformedDataForUI,
        transformedFlatData,
        dashboardNagpData
      } = await NationalAssociationGoalPlanningService.GetNationalAssociationGoals(this.countryKeyDropDownSelected)
      this.countryKeyForLocalCurrency = this.countryKeyDropDownSelected
      this.NAGPData = transformedDataForUI
      this.dashboardNagpData = dashboardNagpData
      transformedFlatData.forEach(x => {
        if (this.formData.hasOwnProperty(x.NAFormKey)) {
          this.formData[x.NAFormKey] = x.NAGoal
        }
      })

      transformedDataForUI.forEach(x => {
        this.FY1GoalTotalByNACategoryMap.set(x.NAGoalCategory, x.NAFY1GoalsTotalByCategory)
      })
    },
    async countryGoHandler () {
      await this.fetchNAGPGoalsData()
    },
    formatNumberUS (num) {
      return FormatNumber(num)
    },
    hasFormValidationError () {
      return this.$v.formData.$invalid
    },
    async addOrUpdateNAGPGoalsHandler () {
      if (!this.hasFormValidationError()) {
        this.formData.userName = this.userName
        this.formData.countryKey = this.countryKeyDropDownSelected
        this.formData.fiscalYear = this.dashboardNagpData.FiscalYear
        await NationalAssociationGoalPlanningService.AddOrUpdateNationalAssociationGoals(this.formData)
      }
    },
    async lockUnlockNAGPGoalsHandler () {
      const payload = {
        cnt_key: this.countryKeyDropDownSelected,
        nag_isLocked: !this.dashboardNagpData.IsLocked,
        nag_modified_by: this.userName
      }
      await NationalAssociationGoalPlanningService.LockNationalAssociationGoals(payload)
      await this.fetchNAGPGoalsData()
    },
    isNACategoryReceiptsType (NACategoryName) {
      return NACategoryName.toUpperCase() === NAGoalCategory.Receipts
    },
    calculateNAGoalsTotalByCategoryName (NACategoryName) {
      const formKeys = this.NACategoryGoalsMap.get(NACategoryName)
      let total = new Decimal(0.00)
      Decimal.set({ precision: 100 })
      formKeys.forEach(key => {
        try {
          const val = new Decimal(this.formData[key])
          total = total.plus(val)
        } catch (err) {
          console.error('national_association_goal_value_parsing_error', err)
          total = total.plus(0.00)
        }
      })
      return total.toString()
    },
    async formatCountryObject () {
      this.hasHeadquaterRole = this.userLogin.croles.find(croles => croles.role_name === 'Headquarters')
      const countriesList = await NationalAssociationGoalPlanningService.GetNationalAssociationGoalsCountry()
      createLocaleCurrencyCountryMap(countriesList)
      const countriesOptionList = countriesList.map(country => {
        return {
          value: country.cnt_key.toUpperCase(),
          text: country.cnt_name
        }
      })

      this.countryOptions = countriesOptionList

      // Set the default selection based on userCountryObj.key
      const matchedCountry = countriesOptionList.find(
        option => option.value.toLowerCase() === this.userCountryObj.key.toLowerCase()
      )
      if (matchedCountry) {
        this.countryKeyDropDownSelected = matchedCountry.value // Set to user's country key
      } else {
        // If no match, default to Brazil
        const defaultCountry = countriesOptionList.find(option => option.text.toLowerCase() === 'australia')
        if (defaultCountry) {
          this.countryKeyDropDownSelected = defaultCountry.value
        }
      }
    }
  },
  validations: {
    formData: {
      [NAGPFormKeys.BARNABAS_FUND]: { required, minGoalValue: true },
      [NAGPFormKeys.GIDEON_SCRIPTURE]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.AUXILIARY_SCRIPTURE]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.ADMIN_RECEIPTS]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.GIDEON_PLACEMENTS]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.AUXILIARY_PLACEMENTS]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.GIDEON_PWT]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.AUXILIARY_PWT]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.CHURCH_PRESENTATION]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.GIDEON_MEMBERSHIP]: { required, minGoalValue: customGoalMinValidator },
      [NAGPFormKeys.AUXILIARY_MEMBERSHIP]: { required, minGoalValue: customGoalMinValidator }
    }
  },
  async created () {
    await this.formatCountryObject()
    await this.fetchNAGPGoalsData()
  }
}
</script>

<style lang="scss">
.page-body-spacing {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ngpa-input-grp {
  .input-group {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

:deep(.ngpa-input-grp) {
  label.form-control:disabled {
    background-color: #f2f2f2;
    color: #000;
    pointer-events: none;
  }
}

.ngpa-input-grp .input-group .form-control {
  width: 350px;
  max-width: 350px;

  &:disabled,
  &.disabled {
    background-color: #f2f2f2;
    color: #000;
    pointer-events: none;
  }
}

.error {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-top: 4px;
  margin-left: 50px;
}

.camp-lock-text {
  color: #155724;
  font-size: 25px;
  margin-left: 5px;
}

.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}

.badge {
  position: relative;
  padding: 0.35rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
}

.badge--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;
      border: none;
      border-radius: 0;

      img {
        margin-right: 5px;
      }
    }
  }
}

.g-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  .g-panel__lft {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

    p {
      font-style: italic;
      color: #747474;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 10px;
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
        color: #000;
        font-weight: 700;
        list-style: none;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .lft_font {
      font-size: 20px;
      font-style: normal;
      line-height: 28px;
    }

    .lft__width {
      max-width: 670px;
    }
  }

  .g-panel__rgt {
    display: flex;
    align-items: flex-start;
  }
}

.btn {
  padding: 11px 13px;
  font-size: 15px;
  line-height: 22px;
  text-transform: capitalize;
  border: none;
  border-radius: 0;

  &.btn-primary:not(:disabled) {

    &:hover,
    &:active {
      background-color: #fbc301;
    }
  }

  &.btn-secondary:not(:disabled) {
    background-color: #fbc301;

    &:hover,
    &:active {
      background-color: #003946;
    }
  }

  &:first-child {
    margin-right: 12px;
  }

  img {
    margin-right: 3px;
  }
}

.custom-table {
  tfoot {
    border: none;

    tr {
      border: none;
    }

    th {
      border: none;
      color: #212529;
      background-color: #fff;

      &:first-child {
        text-align: left;
      }
    }
  }

  th {
    background-color: #003b48;
    color: #fff;
    text-align: right;

    &:first-child {
      width: 220px;

      div {
        display: none;
      }
    }
  }

  td {
    text-align: right;
    border-top: none;

    &:first-child {
      width: 350px;
      text-align: left;
    }

    &:nth-child(2) {
      width: 230px;
    }
  }

  .badge {
    position: relative;
    padding: 0.35rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    font-weight: normal;
  }

  .badge--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .edit-circle {
    width: 24px;
    height: 24px;
    background: #000;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #fbc301;
    }
  }
}

.input-group {
  .input-group-text {
    background: #e6e6e6;
    border-color: #777777;
    border-radius: 0 !important;
    font-size: 16px !important;
    line-height: 21px !important;
  }

  .form-control {
    border-radius: 0;
    border-color: #777777;
    text-align: right;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
}

.form-control {
  text-align: right;
  font-weight: 400;
}

select.form-control {
  height: auto;
  text-align: left;
}

.button-div {
  background-color: #003946;
  padding: 12px 22px;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 45px;
    line-height: 48px;
  }

  .g-panel {
    flex-direction: column;

    .g-panel__rgt {
      margin-top: 10px;
    }
  }

  .custom-table {
    td {
      &:first-child {
        width: 220px;
      }

      &:nth-child(2) {
        width: 230px;
      }
    }
  }
}
</style>
