/*eslint-disable*/
import { NAGP_GOAL_MIN_VALUE } from '@/constants/nagp/NationalAssociationGoalPlanningConstants.js'
import Decimal from 'decimal.js'
function customGoalMinValidator (NAGPGoal) {
  // NAGPGoal - value will always be string
  try {
    const d = new Decimal(NAGPGoal)
    return d.greaterThanOrEqualTo(NAGP_GOAL_MIN_VALUE)
  } catch (err) {
    console.error('Decimal_parsing_error_custom_min_validator', err)
    return false
  }
}

export { customGoalMinValidator }
